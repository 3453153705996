<template>
  <div ref="containerParent">
    <template v-if="readonly && contentMayHaveChanged">
      <div class="d-flex ma-2 align-center">
        <span class="body-2">
          {{ $t("evalmee.home.teacher.quizEditor.question.spreadSheet.readOnly") }}
        </span>
        <v-spacer />
        <eva-button
          very-dense
          icon-left="mdi-refresh"
          :label="$t('evalmee.home.teacher.quizEditor.question.spreadSheet.reload')"
          @click="reloadSheet "
        />
      </div>
      <v-divider />
    </template>
    <div
      ref="container"
      class="univer-container"
      :class="{
        'hide-menus': hideMenus,
        'hide-formula-bar': hideFormulaBar,
        'hide-footer': hideFooter,
      }"
      :style="{ height }"
    />
  </div>
</template>

<script>
import { useActiveElement, useDebounceFn, useMouseInElement } from "@vueuse/core"
import lodashReplacementHelper from "@/helpers/lodash_replacement_helpers"
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue"
import { useKeyboardLock } from "@/composables/useKeyboardLock"

const { Univer, LocaleType, Tools, UniverInstanceType, FUniver } = window.UniverCore
const { defaultTheme } = window.UniverDesign
const { UniverRenderEnginePlugin } = window.UniverEngineRender
const { UniverFormulaEnginePlugin } = window.UniverEngineFormula
const { UniverUIPlugin } = window.UniverUi
const { UniverSheetsPlugin } = window.UniverSheets
const { UniverSheetsUIPlugin } = window.UniverSheetsUi
const { UniverDocsPlugin } = window.UniverDocs
const { UniverDocsUIPlugin } = window.UniverDocsUi
const { UniverSheetsFormulaPlugin } = window.UniverSheetsFormula
const { UniverSheetsFormulaUIPlugin } = window.UniverSheetsFormulaUi
const { UniverSheetsNumfmtPlugin } = window.UniverSheetsNumfmt
const { UniverSheetsNumfmtUIPlugin } = window.UniverSheetsNumfmtUi

const UniverSheetsUiEnUS = window.UniverSheetsUiEnUS
const UniverSheetsFormulaUiEnUS = window.UniverSheetsFormulaUiEnUS
const UniverUiEnUS = window.UniverUiEnUS
const UniverDocsUiEnUS = window.UniverDocsUiEnUS
const UniverDesignEnUS = window.UniverDesignEnUS
const UniverDesignFrFR = window.UniverDesignFrFR
const UniverUiFrFR = window.UniverUiFrFR
const UniverDocsUiFrFR = window.UniverDocsUiFrFR
const UniverSheetsUiFrFR = window.UniverSheetsUiFrFR
const UniverSheetsFormulaUiFrFR = window.UniverSheetsFormulaUiFrFR

export default defineComponent({
  name: "SpreadSheet",
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideMenus: {
      type: Boolean,
      default: false,
    },
    hideFormulaBar: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: "500px",
    },
    updateOnDataChanges: {
      type: Boolean,
      default: false,
    },
    emitChanges: {
      type: Boolean,
      default: true,
    },
    locale: {
      type: String,
      default: "en",
    },
  },
  setup(props, { emit }) {
    const containerParent = ref(null)
    const container = ref(null)
    const univer = ref(null)
    const workbook = ref(null)
    const contentMayHaveChanged = ref(false)
    const univerAPI = ref(null)

    const { isOutside } = useMouseInElement(containerParent)
    const { lockKeys, unlockKeys } = useKeyboardLock()

    const mouseIsHovering = computed(() => !isOutside.value)

    const isFocused = ref(false)
    const activeElement = useActiveElement()

    const isFocusedOrHovering = computed(() => isFocused.value || mouseIsHovering.value)

    watch(activeElement, (el) => {
      isFocused.value = el?.classList?.contains("univer-editor")
    })

    watch(isFocusedOrHovering, async (focused) => {
      if (focused) {
        await lockKeys(["Escape"])
      } else {
        unlockKeys(["Escape"])
      }
    })

    const isEmpty = (data) => {
      if (!data?.sheets) return true

      const sheets = Object.values(data.sheets)

      if (sheets.length === 0) return true

      return sheets.every(sheet => {
        const cellData = sheet.cellData
        if (cellData.length === 0) return true

        return Object.values(cellData).every(cell =>
          Object.values(cell).every(value => value.v === "")
        )
      })
    }

    const handleMutation = (command) => {
      const commandType = command.id.split(".")[1]
      const commandDomain = command.id.split(".")[0]

      if (commandType === "mutation" && commandDomain !== "formula") {
        contentMayHaveChanged.value = true
        if (props.readonly) return
        debouncedSave()
      }
    }

    const saveIfSheetsHasChanged = () => {
      if (!props.emitChanges) return

      const newData = workbook.value?.save()
      if (!newData) return

      const newDataIsEmpty = isEmpty(newData)
      const dataIsEmpty = isEmpty(props.data)

      if (dataIsEmpty && newDataIsEmpty) return

      emit("update:data", newData)
      emit("change", newData)
    }

    const debouncedSave = useDebounceFn(saveIfSheetsHasChanged, 500, { maxWait: 5000 })

    const init = (data = props.data) => {
      const localeType = props.locale === "fr" ? LocaleType.FR_FR : LocaleType.EN_US

      const univerInstance = new Univer({
        theme: defaultTheme,
        locale: localeType,
        locales: {
          [LocaleType.EN_US]: Tools.deepMerge(
            UniverDocsUiEnUS,
            UniverSheetsUiEnUS,
            UniverSheetsFormulaUiEnUS,
            UniverUiEnUS,
            UniverDesignEnUS,
          ),
          [LocaleType.FR_FR]: Tools.deepMerge(
            UniverDocsUiFrFR,
            UniverSheetsUiFrFR,
            UniverSheetsFormulaUiFrFR,
            UniverUiFrFR,
            UniverDesignFrFR,
          ),
        },
      })
      univer.value = univerInstance

      univerInstance.registerPlugin(UniverRenderEnginePlugin)
      univerInstance.registerPlugin(UniverFormulaEnginePlugin)

      univerInstance.registerPlugin(UniverUIPlugin, {
        container: container.value,
        contextMenu: true,
      })

      univerInstance.registerPlugin(UniverDocsPlugin, {
        hasScroll: false,
      })
      univerInstance.registerPlugin(UniverDocsUIPlugin)

      univerInstance.registerPlugin(UniverSheetsPlugin)
      univerInstance.registerPlugin(UniverSheetsUIPlugin)
      univerInstance.registerPlugin(UniverSheetsFormulaPlugin)
      univerInstance.registerPlugin(UniverSheetsFormulaUIPlugin)

      univerInstance.registerPlugin(UniverSheetsNumfmtPlugin)
      univerInstance.registerPlugin(UniverSheetsNumfmtUIPlugin)

      workbook.value = univerInstance.createUnit(
        UniverInstanceType.UNIVER_SHEET,
        lodashReplacementHelper.deepClone(data)
      )

      univerAPI.value = FUniver.newAPI(univerInstance)

      univerAPI.value.onCommandExecuted(handleMutation)
    }

    const destroyUniver = () => {
      if (univerAPI.value) {
        univerAPI.value = null
      }
      univer.value?.dispose()
      univer.value = null
      workbook.value = null
    }

    const reloadSheet = () => {
      contentMayHaveChanged.value = false
      init()
    }

    // Watch for data changes
    watch(() => props.data, (val, oldVal) => {
      if (JSON.stringify(val) === JSON.stringify(oldVal)) return
      if (!props.updateOnDataChanges) return
      init(val)
    }, { deep: true })

    // Watch for locale changes
    watch(() => props.locale, () => {
      if (!univer.value) return
      init()
    })

    onMounted(() => {
      init()
    })

    onBeforeUnmount(() => {
      saveIfSheetsHasChanged()
      destroyUniver()
    })

    return {
      containerParent,
      container,
      mouseIsHovering,
      isFocused,
      isFocusedOrHovering,
      contentMayHaveChanged,
      reloadSheet,
      saveIfSheetsHasChanged,
      init,
      destroyUniver,
    }
  },
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.univer-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.hide-menus :deep(.univer-toolbar) {
  display: none ;
}

.hide-formula-bar :deep(.univer-formula-box) {
  display: none ;
}

.hide-footer :deep(footer) {
  display: none ;
}

/* Also hide the menubar */
:global(.univer-menubar) {
  display: none;
}
</style>

<style>
.univer-input-number-input, .univer-input {
  height: inherit !important;
}
</style>
