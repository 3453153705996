<template>
  <div class="d-flex flex-column fill-height">
    <v-row
      class="ma-0 flex-grow-1"
      align="center"
      justify="center"
      v-if="!inline"
    >
      <v-col>
        <v-icon
          x-large
          style="width: 100%"
        >
          {{ icon }}
        </v-icon>
      </v-col>
    </v-row>
    <div>
      <v-divider v-if="!inline" />
      <v-list
        dense
        class="pa-0"
      >
        <v-list-item>
          <v-list-item-icon
            v-if="inline"
            class="mr-2 border-right-divider"
          >
            <v-icon small>
              {{ icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-tooltip="name">
              {{ name }}

              <span
                class="grey--text ml-2"
                v-if="size"
              >
                {{ prettySize }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action-text>
            <span class="grey--text v-list-item__title ">
              {{ info }}
            </span>
            <v-btn
              icon
              small
              @click.stop="download"
              v-if="downloadable"
            >
              <v-icon small>
                mdi-download
              </v-icon>
            </v-btn>
          </v-list-item-action-text>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import prettyBytes from "pretty-bytes"

export default {
  name: "EvaFileCard",
  props: {
    name: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: "mdi-file-outline",
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: null,
    },
  },

  methods: {
    download() {
      this.$emit("download")
    },
  },

  computed: {
    prettySize() {
      return prettyBytes(this.size)
    },
  },
}
</script>
